import {
	DASHBOARD_ACCOUNT_INFO,
	DASHBOARD_ACCOUNT_INFO_CUSTOMER,
	DASHBOARD_GLOBAL_KPIS,
	DASHBOARD_PERIOD_INFO,
	DASHBOARD_SALES,
	DASHBOARD_NEW_REGISTRATIONS,
	DASHBOARD_ORGANIZATION_RANKS,
	DASHBOARD_ORGANIZATION_PACKAGES,
	DASHBOARD_INCOME,
	DASHBOARD_VOLUME_COMPARITION,
	DASHBOARD_DISTRIBUTOR_PERIOD_INFO,
	DASHBOARD_VOLUME_HISTORY,
	DATE_RANGE_THIS_MONTH,
	DASHBOARD_USER_INFO,
	DASHBOARD_REGIONAL_SALES,
	DASHBOARD_COUNTRY_SALES,
	DASHBOARD_SHOW_INTRO_POPUP,
	DASHBOARD_HIDE_INTRO_POPUP,
	DASHBOARD_TOP_INCOME_EARNERS,
	DASHBOARD_TOP_USER_STATS,
	DASHBOARD_LEADERBOARD,
	DASHBOARD_DOUBLE_REFERRAL_BONUS_PROMO,
	DASHBOARD_ORGANIZATION_NOTIFICATIONS,
	DASHBOARD_RETAIL_PROFIT_BONUS_PROMO,
	DASHBOARD_FAST_START_BONUS,
	DASHBOARD_REFERRAL_BONUS,
	DASHBOARD_PERIOD_INFO_USER,
	DASHBOARD_REGIONAL_SALES_USER,
	DASHBOARD_COUNTRY_SALES_USER,
	DASHBOARD_SALES_USER, GET_ACCOUNT_STEPS,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class Dashboard {
	constructor() {
		const axios = new Req();
		const axiosFiltered = new Req(apiFilters);
		this.data = axios;
		this.dataFiltered = axiosFiltered;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	// Distributor
	getAccountInfo(id) {
		const { method, endpoint } = DASHBOARD_ACCOUNT_INFO;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAccountInfoCustomer(id) {
		const { method, endpoint } = DASHBOARD_ACCOUNT_INFO_CUSTOMER;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	canShowIntroPopup() {
		const { method, endpoint } = DASHBOARD_SHOW_INTRO_POPUP;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	hideIntroPopup() {
		const { method, endpoint } = DASHBOARD_HIDE_INTRO_POPUP;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getUserInfo(id) {
		const { method, endpoint } = DASHBOARD_USER_INFO;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getIncomeTotals(id) {
		const { method, endpoint } = DASHBOARD_INCOME;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getVolumeComparition(id) {
		const { method, endpoint } = DASHBOARD_VOLUME_COMPARITION;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDistributorPeriodInfo(id) {
		const { method, endpoint } = DASHBOARD_DISTRIBUTOR_PERIOD_INFO;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getVolumeHistory(id) {
		const { method, endpoint } = DASHBOARD_VOLUME_HISTORY;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDoubleReferralBonus(id) {
		const { method, endpoint } = DASHBOARD_DOUBLE_REFERRAL_BONUS_PROMO;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRetailProfitBonus(id) {
		const { method, endpoint } = DASHBOARD_RETAIL_PROFIT_BONUS_PROMO;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getFastStartBonus(id) {
		const { method, endpoint } = DASHBOARD_FAST_START_BONUS;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDateRangeMonth() {
		const { method, endpoint } = DATE_RANGE_THIS_MONTH;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	// Admin
	getGlobalKpis() {
		const { method, endpoint } = DASHBOARD_GLOBAL_KPIS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	// Uses Filters
	getPeriodInfo(options) {
		const { method, endpoint } = DASHBOARD_PERIOD_INFO;
		return this.dataFiltered[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPeriodInfoUser(options, userId) {
		const { method, endpoint } = DASHBOARD_PERIOD_INFO_USER;
		return this.dataFiltered[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTotalSales() {
		const { method, endpoint } = DASHBOARD_SALES;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTotalSalesUser(userId) {
		const { method, endpoint } = DASHBOARD_SALES_USER;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getReferralBonus(id) {
		const { method, endpoint } = DASHBOARD_REFERRAL_BONUS;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getNewRegistrations() {
		const { method, endpoint } = DASHBOARD_NEW_REGISTRATIONS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOrganizationRanks() {
		const { method, endpoint } = DASHBOARD_ORGANIZATION_RANKS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOrganizationPackages(payload) {
		const { method, endpoint } = DASHBOARD_ORGANIZATION_PACKAGES;
		return this.data[method](endpoint, payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRegionalSales(payload) {
		const { method, endpoint } = DASHBOARD_REGIONAL_SALES;
		return this.data[method](endpoint, payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRegionalSalesUser(payload, userId) {
		const { method, endpoint } = DASHBOARD_REGIONAL_SALES_USER;
		return this.data[method](endpoint(userId), payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCountrySales(payload) {
		const { method, endpoint } = DASHBOARD_COUNTRY_SALES;
		return this.data[method](endpoint, payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCountrySalesUser(payload, userId) {
		const { method, endpoint } = DASHBOARD_COUNTRY_SALES_USER;
		return this.data[method](endpoint(userId), payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTopIncomeEarners(payload) {
		const { method, endpoint } = DASHBOARD_TOP_INCOME_EARNERS;
		return this.data[method](endpoint, payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTopUserStats(payload) {
		const { method, endpoint } = DASHBOARD_TOP_USER_STATS;
		return this.data[method](endpoint, payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getLeaderboard(payload) {
		const { method, endpoint } = DASHBOARD_LEADERBOARD;
		return this.data[method](endpoint, payload, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOrganizationNotifications(id) {
		const { method, endpoint } = DASHBOARD_ORGANIZATION_NOTIFICATIONS;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAccountSteps() {
		const { method, endpoint } = GET_ACCOUNT_STEPS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Dashboard;
