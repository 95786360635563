<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<b-alert
			v-if="$user.details().type === distributor && remainingDays >= $user.details().remaining_days_terminated && showExpiredAlert"
			variant="warning"
			show>
			<button
				v-if="['xs', 'sm'].includes(windowWidth)"
				type="button"
				class="btn"
				@click="showExpiredAlert = false">
				<i class="fas top fa-times mt-1 mr-1" />
			</button>
			<b-row>
				<b-col
					:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
					class="col-12 col-md-8">
					<h5 :class="!['xs', 'sm'].includes(windowWidth) ? 'float-left' : ''">
						{{ translate('membership_expired_title', {days: $user.details().remaining_days_terminated}) }}
					</h5>
				</b-col>
				<b-col
					:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'pr-0'"
					class="col-12 col-md-4 my-auto">
					<button
						v-if="!['xs', 'sm'].includes(windowWidth)"
						type="button"
						class="btn float-right"
						@click="showExpiredAlert = false">
						<i class="fas fa-times" />
					</button>
					<button
						:class="!['xs', 'sm'].includes(windowWidth) ? 'float-right' : ''"
						class="btn btn-primary"
						@click="$router.push({ name: 'Renewal' })">
						{{ translate('renew_membership') }}
					</button>
				</b-col>
			</b-row>
		</b-alert>
		<!-- <b-alert
			v-if="$user.details().tfa_enabled === 0 && admin.includes($user.details().type)"
			show
			variant="danger">
			<h5
				class="mb-0"
				v-html="get2FAEnableLink" />
		</b-alert> -->
		<b-alert
			v-if="hasPermission() && admin.includes($user.details().type)"
			show
			variant="secondary">
			<div
				class="mb-3">
				<search
					v-model="selectedUser"
					:label-text="translate('autocomplete_label')"
					:endpoint="endpoint"
					:empty-result-message="translate('data_not_found')"
					:default-params="{ role: roles.join(',') }"
					:placeholder="'autocomplete_placeholder'"
					:init-value="getLoadedUser"
					:custom-error="userError"
					class="mb-3"
					q="label"
					return-key="id" />
				<b-button
					variant="primary"
					class="mr-1"
					@click="changeUser">
					{{ translate('search') }}
				</b-button>
				<b-button @click="$router.replace({name: 'Home'})">
					{{ translate('clear') }}
				</b-button>
			</div>
		</b-alert>
		<template v-if="!admin.includes($user.details().type) || $route.name === 'Home'">
			<component :is="dashboard" />
		</template>
		<template v-if="admin.includes($user.details().type) && $route.name !== 'Home'">
			<b-row>
				<b-col class="col-12">
					<b-tabs
						v-model="tabIndex"
						@changed="resetUserId()">
						<b-tab
							v-for="(tabInfo, index) in tabs"
							:key="index">
							<template slot="title">
								<router-link
									v-if="$can(tabInfo.permission_section, tabInfo.permission_action)"
									:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
									:to="handleRoutingTabs(tabInfo.name)"
									class="list-group-item btn text-left">
									{{ translate(tabInfo.translate_key) }}
								</router-link>
							</template>
							<template
								v-if="tabIndex === index"
								class="p-0">
								<router-view :class="windowWidth === 'xs' ? '' : 'p-5'" />
							</template>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
		</template>
	</div>
</template>

<script>
import { SEARCH_USERS } from '@/config/endpoint';
import User from '@/util/User';
import {
	ORDER_FIELD_NAME as sortColName,
	ORDER_DIR_NAME as sortDirName,
} from '@/settings/RequestReply';
import {
	distributor, admin, customer, preDistributor, affiliate, agencies,
} from '@/settings/Roles';
import { MEMBERSHIP_REMAINING_DAYS } from '@/settings/Dates';
import { Dashboard } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { adminDashboardPermissions } from '@/settings/Dashboard';
import distributorDashboard from './components/DistributorDashboard';
import agencyDashboard from './components/AgencyDashboard';
import predistributorDashboard from './components/PreDistributorDashboard';
import customerDashboard from './components/CustomerDashboard';
import affiliateDashboard from './components/AffiliateDashboard';

export default {
	name: 'Home',
	messages: [Dashboard],
	mixins: [WindowSizes, DashboardRedirect],
	data() {
		return {
			endpoint: SEARCH_USERS.endpoint,
			selectedUser: '',
			options: {},
			admin,
			days: 0,
			hours: 0,
			minutes: 0,
			userError: false,
			customer,
			preDistributor,
			adminDashboardPermissions,
			distributor,
			affiliate,
			showExpiredAlert: true,
			remainingDays: MEMBERSHIP_REMAINING_DAYS,
			tabIndex: 0,
			roles: ['distributor', 'pre_distributor', 'agency', 'affiliate', 'customer'],
			distributorTabs: [
				{
					name: 'AdminDistributorsDashboard',
					permission_section: 'dashboard',
					permission_action: 'view',
					translate_key: 'dashboard',
				},
				{
					name: 'DistributorsInformation',
					permission_section: 'distributors_information',
					permission_action: 'view',
					translate_key: 'distributors_information',
				},
				{
					name: 'UsersMainOrders',
					permission_section: 'orders',
					permission_action: 'view',
					translate_key: 'orders',
				},
				{
					name: 'DistributorsDownloads',
					permission_section: 'downloads',
					permission_action: 'view',
					translate_key: 'downloads',
				},
				{
					name: 'DistributorsCommissions',
					permission_section: 'commissions',
					permission_action: 'view',
					translate_key: 'commissions',
				},
				{
					name: 'DistributorsBV',
					permission_section: 'bv',
					permission_action: 'view',
					translate_key: 'bv',
				},
				{
					name: 'DistributorsBinaryTree',
					permission_section: 'binary_tree',
					permission_action: 'view',
					translate_key: 'binary_tree',
				},
				{
					name: 'DistributorsUnilevelSummary',
					permission_section: 'unilevel_summary',
					permission_action: 'view',
					translate_key: 'unilevel_summary',
				},
				{
					name: 'DashboardDistributorOrganization',
					permission_section: 'distributor_organization',
					permission_action: 'view',
					translate_key: 'distributor_organization',
				},
				// {
				// 	name: 'DistributorsAutoshipSummary',
				// 	permission_section: 'autoship_summary',
				// 	permission_action: 'view',
				// 	translate_key: 'autoship_summary',
				// },
				{
					name: 'UsersWalletSettings',
					permission_section: 'wallet_transactions',
					permission_action: 'update_password',
					translate_key: 'wallet_settings',
				},
				{
					name: 'DashboardUserNotes',
					permission_section: 'user_notes',
					permission_action: 'view',
					translate_key: 'user_notes',
				},
			],
			agencyTabs: [
				{
					name: 'AdminAgencyDashboard',
					permission_section: 'agency_dashboard',
					permission_action: 'view',
					translate_key: 'dashboard',
				},
				{
					name: 'AgenciesInformation',
					permission_section: 'distributors_information', // TODO: Change this permission to agencies_information
					permission_action: 'view',
					translate_key: 'agencies_information',
				},
				{
					name: 'UsersMainOrders',
					permission_section: 'orders',
					permission_action: 'view',
					translate_key: 'orders',
				},
				{
					name: 'DashboardUserNotes',
					permission_section: 'user_notes',
					permission_action: 'view',
					translate_key: 'user_notes',
				},
			],
			affiliateTabs: [
				{
					name: 'AdminAffiliateDashboard',
					permission_section: 'dashboard',
					permission_action: 'view',
					translate_key: 'dashboard',
				},
				{
					name: 'AffiliatesInformation',
					permission_section: 'distributors_information', // TODO: Change this permission to affiliates_information
					permission_action: 'view',
					translate_key: 'affiliates_information',
				},
				{
					name: 'UsersMainOrders',
					permission_section: 'orders',
					permission_action: 'view',
					translate_key: 'orders',
				},
				{
					name: 'DashboardUserNotes',
					permission_section: 'user_notes',
					permission_action: 'view',
					translate_key: 'user_notes',
				},
				{
					name: 'AffiliatesCommissions',
					permission_section: 'commissions',
					permission_action: 'view',
					translate_key: 'commissions',
				},
			],
			customerTabs: [
				{
					name: 'AdminCustomerDashboard',
					permission_section: 'dashboard',
					permission_action: 'view',
					translate_key: 'dashboard',
				},
				{
					name: 'CustomersInformation',
					permission_section: 'distributors_information', // TODO: Change this permission to customers customers_information
					permission_action: 'view',
					translate_key: 'customers_information',
				},
				{
					name: 'UsersMainOrders',
					permission_section: 'orders',
					permission_action: 'view',
					translate_key: 'orders',
				},
				{
					name: 'DashboardUserNotes',
					permission_section: 'user_notes',
					permission_action: 'view',
					translate_key: 'user_notes',
				},
			],
		};
	},
	computed: {
		tabs() {
			const tabsByRoute = {
				agency: this.agencyTabs,
				affiliate: this.affiliateTabs,
				customer: this.customerTabs,
				distributor: this.distributorTabs,
				pre_distributor: this.distributorTabs,
			};

			return tabsByRoute[this.$route.query.userType] || this.distributorTabs;
		},
		query() {
			return this.$route.query;
		},
		dashboard() {
			const { distributorId } = this.$route.params;

			if (distributor.includes(this.$user.details().type) || typeof distributorId !== 'undefined') {
				return distributorDashboard;
			}

			if (preDistributor.includes(this.$user.details().type) && this.$can('dashboard', 'view')) {
				return predistributorDashboard;
			}

			if (customer.includes(this.$user.details().type) && this.$can('dashboard', 'view')) {
				return customerDashboard;
			}

			if (affiliate.includes(this.$user.details().type) && this.$can('dashboard', 'view')) {
				return affiliateDashboard;
			}

			if (agencies.includes(this.$user.details().type) && this.$can('dashboard', 'view')) {
				return agencyDashboard;
			}

			return false;
		},
		getLoadedUser() {
			const { distributorId } = this.$route.params;
			if (typeof distributorId !== 'undefined') {
				return distributorId;
			}
			return '';
		},
		get2FAEnableLink() {
			return this.translate(
				'activate_2fa',
				{
					fname: this.$user.details().name,
					link: this.$router.resolve({ name: 'TwoFactorAuthentication' }).href,
				},
			);
		},
	},
	beforeRouteUpdate(to, from, next) {
		delete to.query[sortColName];
		delete to.query[sortDirName];
		this.resetUserId();
		next();
	},
	watch: {
		selectedUser() {
			this.userError = false;
		},
	},
	created() {
		if (typeof this.$route.params.distributorId === 'undefined') {
			this.$route.params.distributorId = this.$route.params.userId;
		} else {
			this.selectedUser = this.$route.params.distributorId;
		}
		// This assign is for CustomersInformation to work
		this.$route.params.customerId = this.$route.params.distributorId;
	},
	mounted() {
		if (typeof this.$route.name !== 'undefined') {
			this.tabIndex = this.tabs.findIndex((tabInfo) => tabInfo.name === this.$route.name);
		}
	},
	methods: {
		getDataFiltered() {
			const { query } = this.$route;
			this.options = { ...query, ...this.filters };
		},
		changeUser() {
			const { query, params } = this.$route;
			const oldDistributorId = params.distributorId;
			if (oldDistributorId !== this.selectedUser) {
				params.distributorId = this.selectedUser;
				User.userExists(params.distributorId).then((response) => {
					const { user } = response.response;
					this.cleanRouteQuery(query, oldDistributorId, user.id.toString());
					params.distributorId = user.id.toString();
					params.userId = user.id.toString();
					query.userType = user.type;
					if (this.roles.includes(user.type)) {
						this.toDashboard(params, query);
					} else {
						this.userError = this.translate('invalid_user');
					}
				}).catch((error) => {
					let message = '';
					Object.keys(error.errors).forEach((key) => {
						error.errors[key].forEach(() => {
							message += `${error.errors[key]}  `;
						});
					});
					this.userError = message;
				});
			}
			return null;
		},
		hasPermission() {
			return adminDashboardPermissions.some((item) => this.$can(item.section, item.view));
		},
		handleRoutingTabs(routeName) {
			const { params, query } = this.$route;
			if (routeName === 'DistributorsBinaryTree') {
				if (typeof params.userId === 'undefined') params.userId = params.distributorId;
				if (typeof query.type === 'undefined') query.type = 'binary';
			}

			return { name: routeName, params, query };
		},
		resetUserId() {
			if (this.$route.name !== 'DistributorsBinaryTree') {
				delete this.$route.query.type;
			}
			if (typeof this.$route.query.type === 'undefined') {
				this.$route.params.userId = this.$route.params.distributorId;
			}
		},
		cleanRouteQuery(query, oldDistributorId, newDistributorId) {
			const keys = Object.keys(query);
			for (let i = 0; i < keys.length; i += 1) {
				if (query[keys[i]] === oldDistributorId) {
					query[keys[i]] = newDistributorId;
				}
			}
		},
	},
};
</script>

<style>
	.top {
		position: absolute;
		top: 0;
		right: 0;
	}
</style>
<style scoped>
	.fa-times {
		color: #DEB887	;
	}
</style>
