<template>
	<div class="card mb-0 h-100">
		<div
			v-if="!loading"
			class="card-body p-3 h-100">
			<b-row class="h-100 align-content-between">
				<b-col class="mb-0 col-md-8 col-sm-8 col-xs-8">
					<div
						:title="translate('current_qualification')"
						class="h4 font-weight-bold mb-0">
						{{ translate('associate_qualification') }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold">{{ translate(fullyQualified ? 'qualified' : 'not_qualified') }}</small>
				</b-col>
				<b-col class="col-md-4 col-sm-4 col-xs-4 text-right">
					<div class="row no-gutters justify-content-end">
						<div class="col-auto">
							<img
								:src="require(`@/assets/images/themes/${themeName}/qualified/${cycleRecognition()}.png`)"
								alt="">
						</div>
					</div>
				</b-col>
				<b-col class="col-12 p-0">
					<hr class="m-2">
				</b-col>
				<b-col class="col-12">
					<b-row class="align-items-center">
						<b-col class="col-1">
							<img
								:src="require(`@/assets/images/themes/${themeName}/qualified/${isActive ? 'check_positive' : 'check_negative'}.png`)"
								alt="">
						</b-col>
						<b-col class="col-10">
							<b-row class="no-gutters align-items-center">
								<b-col class="col-5">
									<h6 class="d-inline font-weight-bold text-muted pl-2">
										{{ translate('active') }}
									</h6>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
				<b-col class="col-12 p-0">
					<hr class="m-2">
				</b-col>
				<b-col class="col-12">
					<b-row class="align-items-center">
						<b-col class="col-1">
							<img
								:src="require(`@/assets/images/themes/${themeName}/qualified/${accountInfoData.qualification.status == 'qualified' ? 'check_positive' : 'check_negative'}.png`)"
								alt="">
						</b-col>
						<b-col class="col-6">
							<h6 class="d-inline font-weight-bold text-muted pl-2">
								{{ translate('qualified') }}
							</h6>
						</b-col>
						<b-col :class="['xs'].includes(windowWidth) ? 'col-12' : 'col-5'">
							<p
								v-if="qualifyBeforeDate && !qualifyBeforeDatePassed"
								class="float-right text-muted text-truncate">
								<small
									class=""
									:title="translate('due_date_qualify')">{{ translate('due_date_qualify') }}: </small>
								<small
									:title="$moment(qualifyBeforeDate.date).format(dateFormat)"
									class="text-primary font-weight-bold w-auto">{{ $moment(qualifyBeforeDate.date).format(dateFormat) }}
								</small>
							</p>
						</b-col>
					</b-row>

					<b-row
						no-gutters
						class="mt-3 align-items-center justify-content-around text-center border py-2 px-1 flex-nowrap legs-wrapper">
						<i
							:class="accountInfoData.qualification.legs.has_left_leg ? 'text-lime border-lime' : 'border text-secondary border-secondary' "
							class="fas fa-check fa-xs leg-badge leg-badge-left" />
						<i
							:class="accountInfoData.qualification.legs.has_right_leg ? 'text-lime border-lime' : 'border text-secondary border-secondary' "
							class="fas fa-check fa-xs leg-badge leg-badge-right" />
						<b-col class="col-lg-5 ">
							<div
								:title="translate('left_leg')"
								class="text-muted text-truncate">
								{{ translate('left_leg') }}
							</div>
							<div :class="accountInfoData.qualification.legs.has_left_leg ? 'text-muted font-weight-bold' : 'text-danger'">
								{{ translate(accountInfoData.qualification.legs.has_left_leg ? 'active' : 'inactive') }}
							</div>
						</b-col>
						<b-col class="col-lg-2">
							<img
								:src="require(`@/assets/images/themes/${themeName}/qualified/${isActive ? 'customer_30_green' : 'customer_30_gray'}.png`)"
								alt="">
						</b-col>
						<b-col class="col-lg-5 ">
							<div
								:title="translate('right_leg')"
								class="text-muted text-truncate">
								{{ translate('right_leg') }}
							</div>
							<div :class="accountInfoData.qualification.legs.has_right_leg ? 'text-muted font-weight-bold' : 'text-danger'">
								{{ translate(accountInfoData.qualification.legs.has_right_leg ? 'active' : 'inactive') }}
							</div>
						</b-col>
					</b-row>
				</b-col>
				<b-col class="col-12">
					<b-row
						v-if="showCustomers"
						no-gutters
						class=" mt-2 align-items-center justify-content-center py-2">
						<div class="text-muted col-sm-2 col-lg-auto mr-lg-3 mb-1 text-center">
							{{ translate('customers') }}:
						</div>
						<b-row
							class="col-sm-auto col-xs-12 col-lg-auto justify-content-center">
							<div
								v-for="i in customersToShow"
								:key="i">
								<span
									v-if="accountInfoData.qualification.num_customers >= i"
									class="customer-badge border-lime ml-1">
									<img
										:src="require(`@/assets/images/themes/${themeName}/qualified/customer_20_green.png`)"
										alt="">
									<i class="fas fa-check text-lime" />
								</span>
								<span
									v-else
									class="customer-badge border-secondary border ml-1">
									<img
										:src="require(`@/assets/images/themes/${themeName}/qualified/customer_20_gray.png`)"
										alt="">
									<i class="fas fa-check text-secondary" />
								</span>
							</div>
						</b-row>
					</b-row>
				</b-col>
				<b-col class="col-12 p-0">
					<hr class="m-2">
				</b-col>
				<b-col class="col-12">
					<b-row class="align-items-center">
						<b-col class="col-1">
							<img
								:src="require(`@/assets/images/themes/${themeName}/qualified/${isPackageCheck ? 'check_positive' : 'check_negative'}.png`)"
								alt="">
						</b-col>
						<b-col
							:class="isSmallScreen ? 'col-10' : 'col-5' ">
							<h6 class="d-inline font-weight-bold text-muted pl-2 text-nowrap">
								{{ translate('product_positive_condition') }}
							</h6>
							<br>
							<span
								v-if="!hidePackage && !['xs'].includes(windowWidth) "
								class="pl-2">
								<span class="text-primary font-weight-bold">{{ bvsForUpgrade }} BV </span> <span>{{ translate('to') }} {{ translate(nextPackage.attributes.code_name) }}</span>
								<span
									v-if="bvsForUpgrade <= 0"
									v-b-tooltip.hover
									:title="translate('package_ready_for_upgrade')"
									class="text-muted">
									&nbsp;
									<i class="fas fa-xs fa-info-circle" />
								</span>
							</span>
						</b-col>
						<b-col
							:class="isSmallScreen ? 'col-12 mt-2' : 'col-6' ">
							<span
								v-if="!hidePackage && ['xs'].includes(windowWidth) ">
								<span class="text-primary font-weight-bold">{{ bvsForUpgrade }} BV </span> <span>{{ translate('to') }} {{ translate(nextPackage.attributes.code_name) }}</span>
								<span
									v-if="bvsForUpgrade <= 0"
									v-b-tooltip.hover
									:title="translate('package_ready_for_upgrade')"
									class="text-muted">
									&nbsp;
									<i class="fas fa-xs fa-info-circle" />
								</span>
							</span>
							<p
								v-if="!hasLastPackage || packageIndex === 0"
								class="text-muted"
								:class="!isSmallScreen ? 'float-right text-right' : ''">
								<span
									:title="translate('upgrade_date_limit')"
									class="">{{ showUpgradeDate ? translate('upgrade_date_limit') : translate('upgrade_date') }}: </span>
								<span class="text-primary font-weight-bold"> {{ showUpgradeDate ? upgradeDate.format(dateFormat) : translate('expired') }}</span>
							</p>
						</b-col>
					</b-row>

					<b-row
						v-if="!packages.loading"
						class="flex-nowrap align-items-end text-center mt-2"
						no-gutters>
						<b-col
							v-for="(item) in packagesData"
							:key="item.id"
							:title="translate(item.attributes.code_name)">
							<b-img
								:src="require(`@/assets/images/themes/${themeName}/packages/${item.attributes.code_name}_35x35${packageColor(item)}.png`)"
								class="img-fluid package-image position-relative" />
							<i
								v-if="item.attributes.code_name == activePackage.attributes.code_name"
								class="fas fa-caret-up  fa-lg text-lime position-absolute current-product" />
						</b-col>
					</b-row>
					<b-progress
						:max="maxBvs"
						class="mt-3 bar-width"
						style="height: 1.5rem">
						<b-progress-bar
							:value="totalBvs"
							:variant="isFullAmbassador ? 'lime' : 'primary'" />
						<span
							v-if="totalBvs < maxBvs"
							:class="'text-black'"
							class="text-center w-100 pr-6"
							style="position: absolute; font-size: 1rem">
							{{ totalBvs }} / {{ maxBvs }}
						</span>
					</b-progress>
				</b-col>
			</b-row>
		</div>
		<is-loading
			v-if="loading"
			class="d-flex justify-content-center align-items-center h-100"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading" />
	</div>
</template>

<script>
import { MD_FORMAT, YMDHMS_FORMAT } from '@/settings/Dates';

import GENERAL_INFO from '@/util/GeneralInformation';
import { Packages, AccountInformation, Dashboard } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import { admin } from '@/settings/Roles';

export default {
	name: 'DistributorInfoWidget',
	messages: [Packages, AccountInformation, Dashboard],
	mixins: [WindowSizes],
	props: {
		accountInfoData: {
			type: Object,
			default: () => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
			dateFormat: MD_FORMAT,
			customersToShow: 3,
			packages: new GENERAL_INFO(),
			YMDHMS_FORMAT,
			admin,
		};
	},
	computed: {
		qualifyBeforeDate() {
			try {
				return this.accountInfoData.qualification.qualify_before_date;
			} catch (error) {
				return false;
			}
		},
		qualifyBeforeDatePassed() {
			return this.$moment(this.qualifyBeforeDate.date) < this.$moment().tz(this.qualifyBeforeDate.timezone);
		},
		showCustomers() {
			return this.accountInfoData.qualification.pre_qualification_applies_in.includes(this.accountInfoData.country);
		},
		// Package
		packagesData() {
			try {
				return this.packages.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		orderedPackages() {
			try	{
				const orderedNumbers = {};
				this.packagesData.forEach((item) => {
					orderedNumbers[item.attributes.sort_order] = item.id;
				});
				return orderedNumbers;
			} catch (error) {
				return [];
			}
		},
		activePackage() {
			try {
				const found = this.packagesData.find((pack) => pack.attributes.code_name === this.accountInfoData.product);
				return found || {
					attributes: {
						code_name: 'no_package',
					},
				};
			} catch (error) {
				return {};
			}
		},
		packageIndex() {
			let index = this.packagesData.indexOf(this.activePackage);
			if (index < this.packagesData.length - 1 && index !== -1) {
				index += 0.70;
			} else if (index === this.packagesData.length - 1) { // last_package
				index += 1;
			} else { // no_package
				index = 0;
			}
			return index;
		},
		nextPackage() {
			try {
				if (!this.activePackage) {
					return this.packagesData[0];
				}

				const orderActivePackage = this.activePackage.attributes.sort_order;
				const nextPackageId = this.orderedPackages[orderActivePackage + 1];
				return this.packagesData.filter((item) => item.id === String(nextPackageId))[0];
			} catch (error) {
				return undefined;
			}
		},
		lastPackage() {
			return this.packagesData[this.packagesData.length - 1];
		},
		currentBV() {
			try {
				if (typeof this.accountInfoData.current_bvs === 'number') {
					return this.accountInfoData.current_bvs;
				}
				return 0;
			} catch (error) {
				return 0;
			}
		},
		bvsForUpgrade() {
			try {
				const bvs = this.nextPackage.attributes.volume_required - this.currentBV;
				return bvs < 0 ? 0 : bvs;
			} catch (error) {
				return 0;
			}
		},
		maxBvs() {
			try {
				return this.packagesData[this.packagesData.length - 1].attributes.volume_required;
			} catch (error) {
				return 0;
			}
		},
		// nextPackageBvs() {
		// 	try {
		// 		if (!this.hasLastPackage) {
		// 			return this.nextPackage.attributes.volume_required;
		// 		}

		// 		return this.lastPackage.attributes.volume_required;
		// 	} catch (error) {
		// 		return 0;
		// 	}
		// },
		upgradeDate() {
			try {
				return this.$moment(this.accountInfoData.upgrade_date_limit.date);
			} catch (error) {
				return false;
			}
		},
		showUpgradeDate() {
			try {
				const nowFormat = this.$moment().tz(this.accountInfoData.upgrade_date_limit.timezone)
					.format(YMDHMS_FORMAT);
				const now = this.$moment(nowFormat);
				return (!this.hasLastPackage || this.activePackage.attributes.code_name === 'no_package')
					&& this.upgradeDate >= now;
			} catch (error) {
				return false; // hide date
			}
		},
		hasLastPackage() {
			return typeof this.nextPackage === 'undefined';
		},
		hidePackage() {
			return this.hasLastPackage;
		},
		isPackageCheck() {
			return this.packageIndex >= 0.7; // apprentice index
		},
		isActive() {
			return this.accountInfoData.status === 'active';
		},
		risingStarData() {
			return this.accountInfoData.rising_star;
		},
		isFullAmbassador() {
			return this.accountInfoData.full_ambassador;
		},
		fullyQualified() {
			return this.isActive && this.accountInfoData.qualification.status === 'qualified' && this.isPackageCheck;
		},
		// bvProgress() {
		// 	try {
		// 		const barCheckPoints = {
		// 			2: 6,
		// 			3: 35,
		// 			4: 66,
		// 			5: 100,
		// 		};
		// 		let maxPercent = 100;
		// 		let minPercent = 0;
		// 		if (!this.hidePackage) { // distributor has last package
		// 			maxPercent = barCheckPoints[this.nextPackage.attributes.sort_order];
		// 			minPercent = barCheckPoints[this.activePackage.attributes.sort_order];
		// 		} else if (this.activePackage.attributes.code_name === 'no_package') { // no package
		// 			maxPercent = barCheckPoints[this.packagesData[0].attributes.sort_order];
		// 		}

		// 		const currentBvs = this.totalBvs;
		// 		const partialPercent = maxPercent - minPercent;
		// 		const partialProgress = (currentBvs / this.nextPackageBvs) * partialPercent;
		// 		const bvsProgress = minPercent + partialProgress;
		// 		return  bvsProgress >= 100 ? 100 : bvsProgress;
		// 	} catch (error) {
		// 		return 0;
		// 	}
		// },
		totalBvs() {
			try {
				return this.currentBV;
			} catch (error) {
				return 0;
			}
		},
		isSmallScreen() {
			return this.windowWidthPx < 1580;
		},
	},
	watch: {
		fullyQualified(newValue) {
			this.$emit('fullyQualified', newValue);
		},
		accountInfoData() {
			this.getPackages();
		},
	},
	created() {
		this.getPackages();
	},
	methods: {
		getPackages() {
			if (this.admin.includes(this.$user.details().type)) {
				const { distributorId } = this.$route.params;
				this.packages.getPackages({ distributor_id: distributorId });
			} else if (typeof this.accountInfoData.user_id !== 'undefined' && this.accountInfoData.user_id != null) {
				this.packages.getPackages({ distributor_id: this.accountInfoData.user_id });
			} else {
				this.packages.getPackages({ distributor_id: this.$user.details().id });
			}
		},
		packageColor(item) {
			if (this.activePackage.attributes.code_name === 'no_package') {
				return '_gray';
			}
			if (item.attributes.sort_order > this.activePackage.attributes.sort_order) {
				return '_gray';
			}
			if (item.attributes.sort_order === this.activePackage.attributes.sort_order) {
				return '_green';
			}

			return '_blue';
		},
		cycleRecognition() {
			if (this.fullyQualified) {
				if (this.accountInfoData.cycle_recognition) {
					return this.accountInfoData.cycle_recognition;
				}
				return 'fully_qualified';
			}
			return 'not_fully_qualified';
		},
	},
};
</script>

<style lang="scss" scoped>
.customer-badge {
	border-radius:22px;
	padding:0.4rem
}
.legs-wrapper{
	border-radius:5px;
	position: relative;
}
.leg-badge {
	position: absolute;
	border-radius:50%;
	padding:2px;
	background:white;

	&.leg-badge-left{
		top: -8px;
		left: -8px;
	}

	&.leg-badge-right{
		top: -8px;
		right: -8px;
	}
}
.current-product{
	bottom: -15px;
    left: 45%;
}
.text-lime {
	color: #8bc878;
}
.border-lime {
	border: 1px solid;
	border-color: #8bc878;
}
.bar-width {
	margin-right: 2rem !important;
	margin-left: 2rem !important
}
.pr-6 {
	padding-right: 6rem !important
}
</style>
