<template>
	<div class="card mb-0 h-100">
		<div
			class="card-body p-3 h-100">
			<b-row class="h-100">
				<b-col class="mb-0 col-8">
					<div
						class="h4 mb-0 font-weight-bold d-inline">
						{{ translate('promotions') }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold d-block">
						{{ translate(`${promotions[selectedPromoIndex]}`) }}
						<a
							v-b-tooltip.hover
							:href="infoLink"
							target="_blank"
							:title="infoLink ? translate('view_details') : translate(`${promotions[selectedPromoIndex]}_details`)"
							class="text-decoration-none link-primary mr-2">
							<i class="fas fa-info-circle" />
						</a>
					</small>
				</b-col>
				<b-col class="col-4 text-right">
					<img
						:src="require(`@/assets/images/themes/${themeName}/promotions/widget.png`)"
						alt="">
				</b-col>
				<b-col class="col-12 p-0">
					<hr class="m-1">
				</b-col>
				<b-col class="col-12 p-0">
					<retail-profit-bonus
						v-if="promotions[selectedPromoIndex] === retailProfitBonus"
						:user-id="accountInfoData.user_id"
						@changeLoading="changeLoadingRetailProfitBonus" />
					<referral-bonus
						v-if="promotions[selectedPromoIndex] === referralBonus"
						:user-id="accountInfoData.user_id"
						@changeLoading="changeLoading" />
					<rising-star-details
						v-if="promotions[selectedPromoIndex] === risingStar && (risingStarData.qualified || !risingStarData.qualifying_period.ended || risingStarData.show_amnesty_promo)"
						:associate="fullyQualified"
						:qualified="risingStarData.qualified"
						:min-cycles="risingStarData.min_cycles"
						:cycles="risingStarData.cycles"
						:qualifying-period="risingStarData.qualifying_period"
						:show-amnesty-period="risingStarData.show_amnesty_promo"
						:amnesty-promo="risingStarData.amnesty_promo || {}" />
				</b-col>
				<b-col
					v-if="validPromotions.length > 1"
					class="col-12 my-2 align-self-end">
					<b-row class="align-items-center justify-content-center px-1">
						<b-col

							class="d-none d-sm-block col-3 text-left px-0 pointer text-muted"
							@click="changeIndex(-1)">
							<template v-if="selectedPromoIndex != 0">
								<i class="icon-lg icon-arrow-left" />
							</template>
						</b-col>
						<b-col class="col-12 col-md">
							<item-slided
								:total="validPromotions.length"
								:current="selectedPromoIndex"
								class="text-muted"
								@selectItem="selectItem" />
						</b-col>
						<b-col
							class="d-none d-sm-block col-3 text-right px-0 pointer text-muted"
							@click="changeIndex(1)">
							<template v-if="selectedPromoIndex != validPromotions.length - 1">
								<i class="icon-lg icon-arrow-right" />
							</template>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
		<is-loading
			v-if="loadingDouble"
			class="d-flex justify-content-center align-items-center h-100"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loadingDouble" />
	</div>
</template>

<script>
import {
	PROMOTIONS,
	RISING_STAR_QUALIFIED_PROMOTIONS,
	DETAILS_URL,
	retailProfitBonus,
	// fastStartBonus,
	// doubleUpDoubleDown,
	referralBonus,
	risingStar,
	risingStarAmnesty,
} from '@/settings/Promotions';
import {
	Dashboard, Ranks, Promotions as PromoMessages, Tooltip,
} from '@/translations';
import ItemSlided from '@/components/ItemSlided';
// import DoubleReferralBonusDetails from './widgets/DoubleReferralBonusDetails';
import ReferralBonus from './widgets/ReferralBonus';
import RisingStarDetails from './widgets/RisingStarDetails';
import RetailProfitBonus from './widgets/RetailProfitBonus';
import EventBus from '@/util/eventBus';

export default {
	name: 'PromotionsWidget',
	messages: [Dashboard, Ranks, PromoMessages, Tooltip],
	components: {
		ItemSlided,
		RetailProfitBonus,
		ReferralBonus,
		RisingStarDetails,
	},
	props: {
		accountInfoData: {
			type: Object,
			default: () => {},
		},
		fullyQualified: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
			selectedPromoIndex: 0,
			promotions: PROMOTIONS,
			risingStarPromotions: RISING_STAR_QUALIFIED_PROMOTIONS,
			retailProfitBonus,
			referralBonus,
			// fastStartBonus,
			// doubleUpDoubleDown,
			risingStar,
			risingStarAmnesty,
			loadingRetailProfitBonus: false,
			loadingDouble: false,
		};
	},
	computed: {
		validPromotions() {
			if (!this.risingStarData.qualified && this.risingStarData.qualifying_period.ended && !this.risingStarData.show_amnesty_promo) {
				return this.risingStarPromotions;
			}
			return this.promotions;
		},
		infoLink() {
			let selectedPromo = this.validPromotions[this.selectedPromoIndex];
			if (selectedPromo === this.risingStar && this.risingStarData.show_amnesty_promo) {
				selectedPromo = this.risingStarAmnesty;
			}
			return DETAILS_URL[selectedPromo.toUpperCase()];
		},
		risingStarData() {
			return this.accountInfoData.rising_star;
		},
		selectedPromo() {
			try {
				const data = this.ranksData[this.selectedPromoIndex].attributes;
				return data;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		EventBus.$on('changeWidget', (emitData) => {
			if (this.validPromotions.includes(emitData.index)) {
				this.selectedPromoIndex = this.validPromotions.indexOf(emitData.index);
			}
		});
	},
	methods: {
		changeIndex(value) {
			let upcomingIndex = this.selectedPromoIndex;
			upcomingIndex += value;
			if (upcomingIndex > this.validPromotions.length - 1) this.selectedPromoIndex = 0;
			else if (upcomingIndex < 0) this.selectedPromoIndex = this.validPromotions.length - 1;
			else this.selectedPromoIndex += value;
		},
		selectItem(item) {
			const index = Number(item);
			if (!Number.isNaN(index)) {
				this.selectedPromoIndex = index;
			}
		},
		changeLoadingRetailProfitBonus(newValue) {
			this.loadingRetailProfitBonus = newValue;
		},
		changeLoading(newValue) {
			this.loadingDouble = newValue;
		},
	},
};
</script>
